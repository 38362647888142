export default {
  syx: "SYX2",
  oldsyx: "SYX1",
  oldsyx2: "SYX2",
  newsyx: "SYX",
  usdt: "USDT",
  vlx: "VLX",
  svlx: "SVLX",
  eth: "ETH",
  pvlx: "pVlx",
};
